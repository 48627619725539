<template>
  <div>
    <table-page dataName="receive_logs"
                ref="table"
                :tabList="tabList"
                :searchFun="searchFun"
                :search="search"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchSendPacketRecords">
      <template slot="button">
        <FileExport :params="exportParams">导出领取记录</FileExport>
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="网点">
        <template slot-scope="scope">
          {{ scope.row.marketer_province }}{{ scope.row.marketer_city}}{{ scope.row.marketer_district }}{{ scope.row.marketer_net }}
        </template>
      </el-table-column>
      <el-table-column prop="wx_mini_openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column label="领取状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status ==='RECEIVED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>已领取
          </span>
          <span v-else-if="scope.row.status ==='FAIL'">
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>领取失败
          </span>
          <span v-else>
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>
            <span v-if="scope.row.status ==='PENDING'">待领取</span>
            <span v-if="scope.row.status ==='RECEIVING'">领取中</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="领取金额">
        <template slot-scope="scope">
          {{scope.row.money/100+'元'}}
        </template>
      </el-table-column>
      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="领取时间">
      </el-table-column>

      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="派发员工">
      </el-table-column>
      <el-table-column prop="marketer_tel"
                       show-overflow-tooltip
                       label="员工号码">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="toDetail(scope)">查看详情</el-button>
        </template>
      </el-table-column>
    </table-page>
    <RecordDetail ref='record'
                  @on-success="$refs.table.fetch()" />
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import RecordDetail from './components/RecordDetail'

import FileExport from '@/components/FileExport'
import { fetchSendPacketRecords } from '@/api/market'
export default {
  name: 'SendPacketRecords',
  data () {
    return {
      exportParams: null,
      fetchSendPacketRecords,
      dialogVisible: false,
      paramsObj: {
      },
      exportUrl: '',
      search: [{
        type: 'area'
      }, {
        type: 'input',
        name: '派发员工',
        placeholder: '请输入派发员工手机号码',
        key: 'marketer_tel',
        value: ''
      }, {
        type: 'input',
        name: '手机号码',
        placeholder: '请输入用户手机号码',
        key: 'tel',
        value: ''
      }
      ],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'PENDING',
        label: '待领取'
      }, {
        value: 'FAIL',
        label: '领取失败'
      }, {
        value: 'RECEIVED',
        label: '已领取'
      }]
    }
  },
  components: {
    TablePage,
    FileExport,
    RecordDetail
  },
  methods: {
    searchFun (res) {
      const param = Object.assign({}, res)
      param.marketer_province = res.province
      param.marketer_city = res.city
      param.marketer_district = res.district
      param.marketer_net = res.net
      delete param.province
      delete param.city
      delete param.district
      delete param.net
      return param
    },
    showTable (id) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: id
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
      this.exportParams = e.export
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    toDetail (scope) {
      this.$refs.record.showDetail(scope)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
