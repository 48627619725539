<template>
  <div class="">
    <el-dialog title="领取详情"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="70%">
      <div class="content">
        <el-table :data="tableData"
                  v-loading="loadding"
                  style="width: 100%">
          <el-table-column show-overflow-tooltip
                           label="红包金额">
            <template slot-scope="scope">
              {{scope.row.money/100+'元'}}
            </template>
          </el-table-column>
          <el-table-column label="领取状态"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status ==='RECEIVED'">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已领取
              </span>
              <span v-else-if="scope.row.status ==='FAIL'">
                <el-badge is-dot
                          type="danger"
                          class="mr-15"></el-badge>领取失败
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>
                <span v-if="scope.row.status ==='PENDING'">待领取</span>
                <span v-if="scope.row.status ==='RECEIVING'">领取中</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="是否到账"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.status === 'NORMAL' ? '是' :'否'}}
            </template>
          </el-table-column>
          <el-table-column label="失败原因"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.status === 'FAIL' ? scope.row.err_msg:''}}
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type='text'
                         v-permission="['ADMIN']"
                         v-if="scope.row.status === 'FAIL'"
                         @click="marketerAgainReceive(scope.row)"
                         size="mini">补发</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { marketerAgainReceive } from '@/api/market'
export default {
  name: '',
  data () {
    return {
      dialogVisible: false,
      prize: null,
      tableData: [],
      loadding: false,
      activity_id: ''
    }
  },
  filters: {
    status (val) {
      const statusTypes = {
        NORMAL: '已领取',
        PENNDING: '派发中',
        FAIL: '派发失败'
      }
      return statusTypes[val]
    }
  },
  components: {
  },
  methods: {
    async marketerAgainReceive (item) {
      this.$confirm('确认补发？', {
        type: 'warning'
      }).then(async () => {
        const res = await marketerAgainReceive({
          log_id: item.id
        })
        if (res.meta.code === 0) {
          this.$message.success('补发成功')
          this.dialogVisible = false
          this.$emit('on-success')
        } else {
          this.$message.error(res.meta.msg)
        }
      })
    },
    showDetail (scope) {
      this.dialogVisible = true
      this.tableData = []
      this.tableData.push(scope.row)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
